import { Injectable } from "@angular/core";
import { ReliabilityService } from "src/app/services/reliability.service";

@Injectable()
export class AppSense {

  private pendingChanges:   boolean = false;
  private blnInfoEdits:     boolean = false;
  private blnImpactEdits:   boolean = false;
  private blnActionEdits:   boolean = false;
  private blnFollowUpEdits: boolean = false;  //JCG 8/20/24
  private blnATagEdits:     boolean = false;
  private blnVTagEdits:     boolean = false;
  //--------------------------------------
  private blnDeleteAction:  boolean = false;
  private blnDeletFollowUp: boolean = false;  //JCG 8/20/24
  private blnDeleteImpact:  boolean = false; 
  private blnAddAction:     boolean = false;
  private blnAddFollowUp:   boolean = false;  //JCG 8/20/24
  private blnAddImpact:     boolean = false;
  private blnPageRefresh:   boolean = false;
  //-------------------------------------
  private dataDisplay:      string = 'table'; //JCG 9/15/24
  private impactsLbl:       string = '';
  private actionsLbl:       string = '';

  constructor(private reliabilityService: ReliabilityService){}

  //Labels are modified to tell user of pending edits that need to be saved.
  get infoLabel(){
      return this.blnInfoEdits ? 'Info*' : 'Info';
  }

  //
  set impactsLabel(numImpacts: string) {
    if (numImpacts == null) {
      this.impactsLbl = 'Impacts(0)';
    } else {
      this.impactsLbl = 'Impacts(' + numImpacts + ')';
    }
  }

  get impactsLabel(){
      return this.blnImpactEdits ? this.impactsLbl + '*' : this.impactsLbl;
  }

  set actionsLabel(numActions: string) {
    if (numActions == null) {
      this.actionsLbl = 'Actions(0)';
    } else {
      this.actionsLbl = 'Actions(' + numActions + ')';
    }
  }

  get actionsLabel(){
      return this.blnActionEdits ? this.actionsLbl + '*' : this.actionsLbl;
  }

  get aTagLabel(){
      return this.blnATagEdits ? 'Critical App Tags*' : 'Critical App Tags';
  }

  get vTagLabel(){
      return this.blnVTagEdits ? 'Vendor Tags*' : 'Vendor Tags';
  }

  get followUpLabel() {
    return this.blnFollowUpEdits ? 'Follow-Up Actions*' : 'Follow-Up Actions'
  }

  /******************************************************************
   * Edit Markers (Used to add change flag '*' to Tabs)
   *****************************************************************/
  get pendingEventEdits() :boolean {
    return this.blnInfoEdits;
  }
  set pendingEventEdits(blnTmp: boolean){
    this.blnInfoEdits = blnTmp;
  }
  get pendingActionEdits() :boolean {
    return this.blnActionEdits;
  }
  set pendingActionEdits(blnTmp: boolean){
    this.blnActionEdits = blnTmp;
  }
  get pendingImpactEdits() :boolean {
    return this.blnImpactEdits;
  }
  set pendingImpactEdits(blnTmp: boolean){
    this.blnImpactEdits = blnTmp;
  }
  get pendingATagEdits() :boolean {
    return this.blnATagEdits;
  }
  set pendingATagEdits(blnTmp: boolean){
    this.blnATagEdits = blnTmp;
  }
  get pendingVTagEdits() :boolean {
    return this.blnVTagEdits;
  }
  set pendingVTagEdits(blnTmp: boolean){
    this.blnVTagEdits = blnTmp;
  }
  get pendingUpdateEdits() :boolean {
    return this.blnFollowUpEdits;
  }
  set pendingUpdateEdits(blnTmp: boolean) {
    this.blnFollowUpEdits = blnTmp;
  }

  /******************************************************************
   * Corrective Actions Add/Delete Flags used in refreshing the 
   * corrective actions tab after an update or delete action.
   *****************************************************************/
  get actionAddFlag() {
    return this.blnAddAction;
  }
  set actionAddFlag(blnTmp: boolean) {
    this.blnAddAction = blnTmp;
  } 
  get actionDeleteFlag() {
    return this.blnDeleteAction;
  }
  set actionDeleteFlag(blnTmp: boolean) {
    this.blnDeleteAction = blnTmp;
  }

  /******************************************************************
   * Update Actions (FollowUp) Add/Delete Flags used in refreshing  
   * the Update Actions tab after an update or delete action.
   *****************************************************************/
  get updateAddFlag() {
    return this.blnAddFollowUp;
  } 
  set updateAddFlag(blnTmp: boolean) {
    this.blnAddFollowUp = blnTmp; 
  }
  get updateDeleteFlag() {
    return this.blnDeletFollowUp;
  }
  set updateDeleteFlag(blnTmp: boolean) {
    this.blnDeletFollowUp = blnTmp;
  }


  /******************************************************************
   * Business Impact Add/Delete Flags used in refreshing the Biz
   * Impact tab after an update or delete action.
   *****************************************************************/  
  get impactAddFlag() : boolean {
    return this.blnAddImpact;
  }
  set impactAddFlag(blnTmp: boolean) {
    this.blnAddImpact = blnTmp;
  }
  get impactDeleteFlag() {
    return this.blnDeleteImpact;
  }
  set impactDeleteFlag(blnTmp: boolean) {
    this.blnDeleteImpact = blnTmp;
  }

  /******************************************************************
   * Event Details Page Refresh Flag
   *****************************************************************/  
  get pageRefreshed() {
    return this.blnPageRefresh;
  }
  set pageRefreshed(blnTmp: boolean) {
    this.blnPageRefresh = blnTmp;
  }

  /****************************************************************
   *  Sets Main display (table or list)
   ****************************************************************/
  get eventDisplay() : string {
    return this.dataDisplay;
  }

  set eventDisplay(dsp: string) {
    this.dataDisplay = dsp;
  }

  //Used to determine if there are any unsaved changes
  isPendingChange(){
      if(this.blnInfoEdits){
          //console.log('blnInfoEdits');
          return true;
      } else if(this.blnActionEdits){
          //console.log('blnActionEdits');
          return true;
      } else if(this.blnImpactEdits){
          //console.log('blnImpactEdits');
          return true;
      } else if(this.blnATagEdits){
          //console.log('blnATagEdits');
          return true;
      } else if (this.blnVTagEdits){
          //console.log('blnVTagEdits');
          return true;
      } else {
          return false;
      }
  }//end isPendingChange

  resetEditFlags(){
      this.blnInfoEdits = false;
      this.blnActionEdits = false;
      this.blnImpactEdits = false;
      this.blnATagEdits = false;
      this.blnVTagEdits = false;
  }

}